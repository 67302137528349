import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from './environment';
import { AngularFireModule } from '@angular/fire';

import { MatTabsModule } from '@angular/material/tabs';
import { AppComponent } from './app.component';
import { HelloComponent } from './hello.component';
import { HttpClientModule } from '@angular/common/http';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  imports:      [ HttpClientModule, BrowserModule, FormsModule,ReactiveFormsModule,BrowserAnimationsModule, MatTabsModule,AngularFireModule.initializeApp(environment.firebase) ],
  declarations: [ ContactFormComponent, AppComponent, HelloComponent ],
  bootstrap:    [ AppComponent ],
  
})
export class AppModule { }
