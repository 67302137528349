import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
public form: FormGroup;
private IP: string;
public submitting: boolean;
public submitted: boolean;
public error: string;

  constructor(private fb: FormBuilder, private afs: AngularFirestore, private http:HttpClient) { 
    this.form = fb.group ({
      ContactName: ['',Validators.required],
      ContactEmail: ['',Validators.required],
      ContactPhone:[''],
      ContactText:['',Validators.required]
    })
//    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
//           this.IP=res.ip; console.log (res);});
  }

  ngOnInit() {
    this.submitting=false;
    this.submitted = false;
    this.error = '';
    this.afs.collection('Enquiries');
  }

  onSubmit(){
    if (this.form.valid){
      this.submitting=true;
      setTimeout(() => {
        this.afs.collection('Enquiries').add({
          Date: Date(), 
          Name: this.form.get('ContactName').value,
          Email: this.form.get ('ContactEmail').value,
          Phone: this.form.get('ContactPhone').value,
          Enquiry: this.form.get ('ContactText').value,
  //        IP: this.IP
        }).then(() => {
          this.submitted = true;
          this.submitting=false;
      })
      .catch((error) => {
          this.error = "Error sending enquiry. Please try again later"
          this.submitting=false;
      });
        }, 3000)
    }
  }
}