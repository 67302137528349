export const environment = {
  production: false,
   firebase: {
    apiKey: "AIzaSyD9ObOp4KtUpg0q7osr_GmMb3fgUgKFn2k",
    authDomain: "techki-e8b63.firebaseapp.com",
    databaseURL: "https://techki-e8b63-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "techki-e8b63",
    storageBucket: "techki-e8b63.appspot.com",
    messagingSenderId: "942333186576",
    appId: "1:942333186576:web:ebb81d9b418450e3a97095",
    measurementId: "G-V38MEB6K9L"
   }
 }